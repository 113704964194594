import {useQuery} from "@apollo/client";
import consumersListQueryDocument from "../gql/ConsumersList";
import {useEffect, useState} from "react";
import {ConsumerDataFragment} from "../gql/types/graphql";
import {useFragment} from "../gql/types";
import {fragments} from "../gql/Consumer";
import localforage from "localforage";

localforage.config({name: 'rbn'});

export default function useAllConsumers() {
    const {data} = useQuery(consumersListQueryDocument);

    const [consumers, setConsumers] = useState<ConsumerDataFragment[] | undefined>();

    useEffect(() => {
        if (data?.allConsumers?.nodes) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const consumers = data.allConsumers.nodes.map(fragment => useFragment(fragments.consumerData, fragment));

            setConsumers(consumers);

            localforage.setItem('consumersData', consumers)
                .then(() => {
                    // console.log(`${Date.now()}: updated consumersData`)
                })
                .catch(console.error);
        }
        else {
            localforage.getItem('consumersData').then(consumers => {
                if (!consumers)
                    return;

                setConsumers(_consumersData => {
                    if (_consumersData)
                        return _consumersData;
                    else {
                        // console.log(`${Date.now()}: using cached consumersData`);
                        return consumers as ConsumerDataFragment[];
                    }
                });
            });
        }
    }, [data, setConsumers]);

    return consumers;
}