import React, {useEffect, useMemo, useState} from 'react';
import AuthenticatedPage from '../../components/AuthenticatedPage';
import {useParams} from "react-router";
import {useMutation, useQuery} from "@apollo/client";
import consumerByIdQueryDocument, {updateConsumerMutationDocument} from "../../gql/Consumer";
import Spinner from "../../components/Spinner";
import {Button, Buttons, Input, Select, TextArea} from "../../components/form";
import redeemPointsDocument from "../../gql/RedeemPoints";

const Points:React.FC = () => {
    const {consumerId} = useParams();
    const {data, loading, refetch} = useQuery(consumerByIdQueryDocument, {variables:{id:consumerId}});

    const [advisorsList, setAdvisorsList] = useState([])
    const consumer = data?.consumerById;
    const bta = consumer?.vendors?.bta;

    const [btaAdvisor, setBtaAdvisor] = React.useState(bta?.advisor ?? '');
    const [btaCall, setBtaCall] = React.useState(bta?.call ?? '');

    useEffect(() => {
        if (loading)
            return;

        setBtaAdvisor(bta?.advisor ?? '');
        setBtaCall(bta?.call);
    }, [loading, bta]);

    const [updateConsumerMutation] = useMutation(updateConsumerMutationDocument);

    useEffect(() => {
        if (loading || !consumer?.nodeId)
            return;

        const assignedAdvisor = advisorsList.find((item:any) => item?.email === btaAdvisor || item?.email === btaAdvisor?.email)

        const vendors = Object.assign({}, consumer.vendors, {
            bta: {
                advisor: assignedAdvisor,
                call: btaCall
            }
        });

        const update = {
            nodeId: consumer?.nodeId,
            patch: {
                vendors
            }
        }

        updateConsumerMutation({variables: {input: update}}).then();

    }, [loading, btaAdvisor, btaCall, consumer, updateConsumerMutation, advisorsList]);

    const changeHandler = (fn:any) => (e:any) => {
        fn(e.target.value);
    }

    useEffect(() => {
        fetch(`https://api.rbnrewards.com/data/bta-advisors.json`)
          .then(response => response.json())
          .then(response => setAdvisorsList(response))
          .catch(e => console.error('JSON Error',e));
      },[])
    
    const [points, setPoints] = useState('0');
    const [note, setNote] = useState('');
    const [redeeming, setRedeeming] = useState(false);
    const [redeemPointsMutation] = useMutation(redeemPointsDocument, { variables: {
            input: {
                accountId: consumer?.account?.id,
                quantity: parseInt(points),
                note: note,
                data: {
                    btaAdvisor
                }
            }
        }});
    const redeemPoints = () => {
        setRedeeming(true);

        redeemPointsMutation()
            .then(() => {
                setPoints('0');
                setNote('');
            })
            .finally(() => {
                setRedeeming(false);
                refetch();
            });
    }

    const pointsAvailable = consumer?.account?.pointsAvailable ?? 0;

    const {phone, email} = useMemo(() => {
        let phone = '';
        let email = '';

        if (consumer?.account?.contacts) {
            consumer?.account?.contacts?.nodes?.forEach((contact:any) => {
                if (contact?.type === 'phone')
                    phone = contact.value?.number ?? ''
                else if (contact?.type === 'email')
                    email = contact.value?.address ?? '';
            });
        }

        return {
            phone,
            email
        };
    }, [consumer?.account?.contacts]);

    const advisorOptions = useMemo(() => {
        return advisorsList.map((advisor: any) => {
            return {
                label: advisor.name,
                value: advisor.email
            }
        })
    }, [advisorsList])

    return (
        <AuthenticatedPage title={`Points - ${consumer?.account?.firstName} ${consumer?.account?.lastName}`}>
            {loading ? <Spinner/> : (
                <>
                    <h2 style={{marginBottom: 0}}>{consumer?.account?.firstName} {consumer?.account?.lastName}</h2>
                    <h3 style={{marginTop: 0}}>
                        {phone && <a href={`tel:${phone.replace(/\D+/g, '')}`} style={{marginRight: '2rem'}}>{phone}</a>}
                        {email && <a href={`mailto:${email}`}>{email}</a>}
                    </h3>

                    <Select label="BTA Advisor" items={advisorOptions} onChange={changeHandler(setBtaAdvisor)} value={btaAdvisor?.email || btaAdvisor} id={`select-bta-advisor`}>
                        <option key={'select-bta-advisor-option'} value={''}>Select BTA Advisor</option>;
                    </Select>
                    <Input label="BTA Call" value={btaCall} onChange={changeHandler(setBtaCall)} type="date"/>

                    <table width="100%">
                        <thead>
                        <tr><td>Date</td><td>Type</td><td>Points</td><td>Advisor</td><td>Note</td></tr>
                        </thead>
                        <tbody>
                        {consumer?.account?.pointsLedger?.nodes?.map((ledger:any) => (
                            <tr key={ledger.id}>
                                <td>{new Date(ledger.createdAt).toLocaleDateString()}</td>
                                <td>{ledger.entryType}</td>
                                <td>{ledger.quantity}</td>
                                <td>{ledger.accountByCreatedBy.firstName} {ledger.accountByCreatedBy.lastName}</td>
                                <td>{ledger.note}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <h3>Redeem Points ({pointsAvailable} available)</h3>
                    <Input label="Points" value={points} onChange={changeHandler(setPoints)} type="number" required={true} min={0} max={pointsAvailable}/>
                    <TextArea label="Note" value={note} onChange={changeHandler(setNote)} required={true}/>
                    <Buttons><Button onClick={redeemPoints} disabled={redeeming || !(points && 0 < parseInt(points) && parseInt(points) <= pointsAvailable && note)}>{redeeming ? 'Redeeming...' : 'Redeem RBN Rewards Points'}</Button></Buttons>
                </>
            )}
        </AuthenticatedPage>
    )
}

export default Points;
