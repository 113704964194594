import React, {ChangeEventHandler, ReactNode, useCallback, useMemo, useState} from 'react';

import AuthenticatedPage from "../../components/AuthenticatedPage";
import Spinner from "../../components/Spinner";
import styled from "styled-components";
import useAllConsumers from "../../lib/useAllConsumers";
import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList as List} from "react-window";
import {ConsumerDataFragment} from "../../gql/types/graphql";

interface Column {
    label?: string;
    width: string;
    field?: string;
    type?: string;
    render?: (value: any) => ReactNode;
    align?: 'left' | 'center' | 'right';
    sort?: boolean | string;
}

export const COLUMNS: Column[] = [
    // {width: '12px', render: data => <AccountMenu data={data}/>},
    {label: 'Name', width: '1fr', field: 'name', type: 'text', sort: true, render: data => <a href={`/dashboard/${data.id}`}>{data.name}</a>},
    // {label: 'PES', width: '30px', field: 'pointsEstimateEmailSent', type: 'select', align: 'center'},
    // {width: '205px', render: data => {
    //         const id = data.deal?.id;
    //
    //         if (!id)
    //             return '';
    //
    //         const inquiry = data.inquiry;
    //
    //         return (
    //             <Links>
    //                 <a href={`https://joinrbn.engagebay.com/home#deal/${id}`} target="engagebay">EB</a>
    //                 <span><FaSms color={inquiry?.details?.sms ? '#0d3f88' : '#ccc'}/></span>
    //                 <span title={formatCampaign(data.campaign)}>&copy;</span>
    //                 {inquiry && <Flags inquiry={inquiry} dealId={id} showingDog={data.showingDog === 'Y'}/>}
    //             </Links>
    //         );
    //     }},
    // {label: 'AmEx', width: '50px', field: 'amexConnected', type: 'select', align: 'center'},
    // {label: 'Agent', width: '70px', field: 'providersInArea', type: 'select'},
    {label: 'Location', width: '1fr', field: 'cityState', type: 'text', sort: true},
    {label: 'Close Date', width: '.5fr', field: 'expectedPointsDate'},
    {label: 'Expected Points', width: '.75fr', field: 'expectedPoints', align: 'right'},
    {label: 'Expected Cash Value (Confidential)', width: '.75fr', field: 'expectedCashValue', align: 'right'},
    {label: 'Points Available', width: '.75fr', field: 'pointsAvailable', align: 'right', sort: false},
    {label: 'Cash Value (Confidential)', width: '.75fr', field: 'cashValue', align: 'right', sort: false},

    {label: 'BTA Call', width: '1fr', field: 'btaCall', type: 'date', align: 'right', sort: true},
    {label: 'BTA Advisor', width: '1fr', field: 'btaAdvisor', type: 'text', sort: true},

    {label: 'Email', width: '1.5fr', field: 'email', type: 'text', sort: true},
    // {label: 'Milestone', width: '1fr', field: 'milestone', type: 'multiselect', sort: true, render: data => {
    //         let style: any = {};
    //
    //         switch (data.milestone) {
    //             case 'Connecting to Agent':
    //                 if (!data.agentsPresented)
    //                     style.background = '#f99';
    //                 break;
    //         }
    //
    //         return (
    //             <span style={style}>{data.milestone}</span>
    //         )
    //     }},
    // {label: '🐕', width: '50px', field: 'showingDog', type: 'select', align: 'center'},
    // {label: 'Buy Price', width: '100px', field: 'buyPriceRange', type: 'select'},
    // {label: 'Sell Price', width: '100px', field: 'sellPriceRange', type: 'select'},
    // {label: 'Side', width: '60px', field: 'sides', type: 'select'},
    // {label: 'Progress', width: '75px', field: 'progress', type: 'select', align: 'right'},
    // {label: 'Created', width: '90px', field: 'created', align: 'right', sort: 'createdDate'},
    // {label: 'Appt', width: '160px', field: 'apptScheduled', align: 'right', sort: 'apptScheduledDate'}
];

const mapFields = (c: ConsumerDataFragment) => {
    if (!c)
        return {};

    const {account, engagebay} = c;
    const firstName = account?.firstName;
    const lastName = account?.lastName;
    const accountId = account?.id;
    const email = account?.email;
    const inquiry = c.inquiries?.nodes.length ? c.inquiries.nodes[0] : null;
    const date = new Date(c.createdAt);
    const details = inquiry?.details;
    const buying = details?.buying || null;
    const service = (details?.service || '').replace(/ing$/, '');
    const deals:any[] = Object.values(engagebay?.deals || {}).filter((deal:any) => deal?.track !== 'Inactive');

    let apptScheduled: string|null = null;
    let apptScheduledDate: Date|null = null;

    const bta = c?.vendors?.bta;

    let progress=0, progressTotal=1;

    if (service === 'Both')
        progressTotal += 2;
    else
        progressTotal++;

    const cityState = inquiry?.details.location?.label;

    const expectedPoints: any = deals.reduce((acc: any, deal:any) => {
        if (deal?.pointsIssuedDate)
            return acc;

        const bonusPoints = parseInt(
            (deal.bonusOffer?.trim() || '0')
                .replace(/\s*([,\d]+).*/, '$1')
                .replace(/\D/, '')
        );

        const dealPrice = deal.amount || 0;
        const txnCommissionSplit = deal.txnCommissionSplit || '0';
        const agreementPercent = deal.agreementPercent || '50';

        const expectedPaymentAmount = (dealPrice * (
                Number(txnCommissionSplit.replace(/[^.\d]+/g, '') || '0') / 100) *
            (Number(agreementPercent.replace(/[^.\d]+/g, '') || '0') / 100)
        );

        const points = Math.min(dealPrice, Math.ceil(expectedPaymentAmount * 2/3 * 100));

        return {
            total: acc.total + points + bonusPoints,
            closeDate: deal.closeOfEscrowDate ? Math.min(acc.closeDate, parseInt(deal.closeOfEscrowDate || 0) * 1000) : acc.closeDate
        };
    }, {total: 0, closeDate: Number.MAX_SAFE_INTEGER});

    return {
        id: c.id,
        accountId,
        name: `${firstName} ${lastName}`,
        cityState,
        email,
        tracks: deals.map((deal:any) => deal.track),
        buckets: deals.map((deal:any) => mapBucket(deal)),
        owner: deals.map((deal:any) => deal.owner).join(', '),
        price: buying?.price || '',
        buyPriceRange: service === 'Selling' ? 'selling' : getPriceRangeBucket(details?.buying?.price),
        sellPriceRange: service === 'Buying' ? 'buying' : getPriceRangeBucket(details?.selling?.price),
        progress: Math.round(progress*100/progressTotal),
        created: date?.toLocaleDateString(),
        createdDate: date?.getTime() || 0,
        engagebay,
        apptScheduled,
        apptScheduledDate,
        inquiry,
        pointsAvailable: (account?.pointsAvailable || 0).toLocaleString(),
        pointsLedger: account?.pointsLedger?.nodes,
        cashValue: `$`+((account?.pointsAvailable || 0) / 100).toLocaleString(),
        expectedPoints: expectedPoints.total ? expectedPoints.total.toLocaleString() : '',
        expectedCashValue: `$`+((expectedPoints.total || 0) / 100).toLocaleString(),
        expectedPointsDate: expectedPoints.closeDate !== Number.MAX_SAFE_INTEGER ? new Date(expectedPoints.closeDate).toLocaleDateString() : '',
        btaCall: bta?.call ?? '',
        btaAdvisor: bta?.advisor?.name ?? '',
    }
};

// const Header = styled.div`
//     padding: 1rem;
//     text-align: right;
//
//     & > * {
//         margin-left: 4rem;
//     }
// `;

const Row = styled.div`
    display: grid;
    grid-column-gap: .5rem;
    white-space: nowrap;
    padding: 0 8px;
    align-items: center;
    
    & > div {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    & > div:first-child {
        overflow: visible;
    }
    
    &.grey {
        background: #eee;
    }
    
    &:hover {
        background: #ff0;
    }
`;

const Cell = styled.div`
    position: relative;
    display: grid;
`;

const ConsumersHeading = styled(Row)`
    padding: 0 25px 0 8px;
    margin-bottom: .5rem;
    
    &:hover {
        background: #fff;
    }
    
    ${Cell} {
        text-align: center;
        text-wrap: wrap;
    }    
`;

const Grid = styled.div`
    flex-grow: 1;
    overflow: hidden;
    background: #fff;
`;

interface Sort {
    column: number;
    descending: boolean;
}

const DEFAULT_SORT: Sort = {
    column: COLUMNS.length - 2,
    descending: true
};

interface ConsumersListProps {
    consumers: ConsumerDataFragment[] | undefined;
    filter?:(value: any|null) => boolean;
    columns?: Column[];
    defaultSort?: Sort;
}

export const ConsumersList:React.FC<ConsumersListProps> = ({consumers, filter, columns, defaultSort}) => {
    columns = columns || COLUMNS;

    const [filters, setFilters] = useState<{[field: string]: string}>({});
    const [sort, setSort] = useState<Sort>(defaultSort || DEFAULT_SORT);

    const incomingList:Array<any>|undefined = useMemo(() => {
        let list = consumers
            ?.filter(c => !!c)
            .map(mapFields)
            .filter(c => {
                return c.pointsLedger?.length || (c.expectedPoints && c.expectedPointsDate);
            })
        ;

        if (filter)
            return list?.filter(filter);
        else
            return list;
        }, [consumers, filter]);

    const list:Array<any>|undefined = useMemo(() => {
        if (!incomingList)
            return undefined;

        const list = Object.entries(filters).reduce((list, [field, string]) => {
            const filter = new RegExp(string
                    .trim()
                    .replace(/\(/, '\\(')
                    .replace(/\)/, '\\)'),
                'i');

            return list.filter((data: any) => {
                const value = String(data[field]);

                return filter.test(value);
            });
        }, incomingList);

        const sortColumn = columns && typeof sort?.column === 'number' ? columns[sort.column] : null;
        const sortField = typeof sortColumn?.sort === 'string' ? sortColumn.sort : sortColumn?.field;

        if (sortField) {
            list.sort((a:any, b: any) => {
                const av = a[sortField];
                const bv = b[sortField];

                let result = 0;

                if (typeof av === 'string' && typeof bv === 'string')
                    result = av.localeCompare(bv);
                else if (typeof av === 'number' && typeof bv === 'number')
                    result = av - bv;
                else if (av instanceof Date && bv instanceof Date)
                    result = av.getTime() - bv.getTime();
                else if (av instanceof Date && !bv)
                    result = 1;
                else if (!av && bv instanceof Date)
                    return -1;

                return sort?.descending ? result * -1 : result;
            });
        }

        return list;
    }, [incomingList, columns, filters, sort]);

    const rowStyle = useMemo(() => columns && { gridTemplateColumns: columns.map(({width}) => width).join(' ') }, [columns]);

    const generateRow = useCallback(({data: list, index, style}: any) => {
        if (!(columns && list))
            return null;

        const data = list[index];

        return (
            <Row style={{...style, ...rowStyle}} className={`${data.showingDog === 'Y' ? 'dog' : ''} ${index % 2 === 0 ? 'grey' : ''}`}>
                {columns.map((column, i) => {
                    let value: any = '';

                    if (column.render)
                        value = column.render(data);
                    else if (column.field)
                        value = data[column.field];

                    const textAlign = column.align || 'left';

                    const title = column.field ? data[column.field] : undefined;

                    return (
                        <div key={i} style={{textAlign}} title={title}>{value}</div>
                    );
                })}
            </Row>
        )
    }, [columns, rowStyle]);

    if (!list)
        return <Spinner/>;

    const updateSort = (i: number) => {
        if (!columns)
            return;

        const column = columns[i];

        if (!column?.sort)
            return;

        setSort(sort => {
            return {
                column: i,
                descending: i !== sort?.column ? false : !sort.descending
            }
        });
    }

    const placeholder = `Search ${list.length} consumer${list.length === 1 ? '' : 's'}...`;

    return (
        <Grid>
            <ConsumersHeading style={rowStyle}>
                {columns.map((column, i) => (
                    <Cell key={i}>
                        {column.label && <strong onClick={() => updateSort(i)}>{column.label}{i === sort?.column ? (sort.descending ? ' ▼' : ' ▲') : ''}</strong>}
                        <Filter column={column} list={incomingList} filters={filters} setFilters={setFilters} placeholder={placeholder}/>
                    </Cell>
                ))}
            </ConsumersHeading>
            <AutoSizer>
                {({height, width}) => (
                    <List
                        height={height - 50}
                        itemCount={list.length}
                        itemData={list}
                        itemSize={35}
                        width={width}
                        style={{flexGrow: 1}}
                    >
                        {generateRow}
                    </List>
                )}
            </AutoSizer>
        </Grid>
    );
};

const Input = styled.input`
    width: 100%;
`;

const Select = styled.select`
    width: 100%;
`;

interface FilterProps {
    column: Column;
    list: any[] | undefined;
    filters: {[field: string]: string};
    setFilters: React.Dispatch<React.SetStateAction<{[p: string]: string}>>;
    placeholder: string;
}

const Filter:React.FC<FilterProps> = ({column, list, filters, setFilters, placeholder}:FilterProps) => {
    const options = useMemo(() => {
        const field = column.field;

        if (!(field && column.type?.match(/select|multiselect/)))
            return undefined;

        const options: string[] = [];

        list?.forEach(row => {
            let values = row[field];

            if (field.match(/milestone/))
                values = values.split(/, /);
            else
                values = [values];

            values.forEach((value: string) => {
                if (value && !options.includes(value))
                    options.push(value);
            });
        });

        options.sort();

        return options;
    }, [column, list]);

    const onChange: ChangeEventHandler<HTMLInputElement|HTMLSelectElement> = e => {
        if (!column.field)
            return;

        const value = e.target.value;

        setFilters(filters => ({...filters, [column.field as string]: value}));
    }

    if (column.field && column.type) {
        if (options) {
            return (
                <Select value={filters[column.field] || ''} onChange={onChange} style={{width: '100%'}}>
                    <option value=""/>
                    {options.map(option => <option key={option}>{option}</option>)}
                </Select>
            );
        }

        if (column.type === 'text') {
            return (
                <Input value={filters[column.field] || ''} onChange={onChange} style={{width: '100%'}} placeholder={placeholder}/>
            );
        }
    }

    return <span/>;
}

const Consumers:React.FC = () => {
    // const {viewer} = useViewer();

    const consumers = useAllConsumers();

    return (
        <AuthenticatedPage title="RBN - BTA Dashboard" fullWidth={true} padding="0 0 1rem">
            <ConsumersList consumers={consumers}/>
        </AuthenticatedPage>
    );
}

export default Consumers;

export function getPriceRangeBucket(priceRange:string) {
    if (!priceRange)
        return 'N/A';

    const price = Number((priceRange || '0').replace(/.*\$([,\d]+).*/, '$1').replace(/\D+/g,''));

    if (price < 500000)
        return ' Under $500k';
    else if (price < 750000)
        return '  $500k-$750k';
    else if (price < 1000000)
        return '   $750k-$1m';
    else if (price < 5000000)
        return '    $1m-$5m';
    else
        return '     $5m+';
}

export function mapBucket(deal:{track:string, milestone:string, side?:string}) {
    if (!(deal && deal.track && deal.milestone))
        return 'UNMAPPED';

    switch (deal.track) {
        case 'Inactive':
            switch (deal.milestone) {
                case 'Lost Opportunity':
                case 'Not a Good Fit':
                case 'Stop Rcvd':
                    return 'Not Interested';
                default:
                    return deal.milestone;
            }

        case 'Member Leads':
            if (deal.milestone.indexOf('Following Up') === 0)
                return 'Warm Lead';

            switch (deal.milestone) {
                case 'Fake':
                case 'Do Not Contact':
                case 'Stop Rcvd':
                    return 'Dead';
                case 'Strategic Drop':
                case 'No Agent Found':
                    return 'Strategic Drop';
                case 'Not a Good Fit':
                    return 'Not Interested';
                case 'New':
                case 'Attempting to Contact':
                case 'Following Up (No Drip)':
                case 'Following Up (With Drip)':
                case 'No Amex - New':
                case 'Yes AmEx - New':
                case 'No AmEx - Appt Scheduled':
                case 'Yes AmEx - Appt Scheduled':
                case 'Old Queue':
                case 'No Show':
                    return 'New';
                case 'Find an Agent (4+ Weeks)':
                case 'Appt Scheduled':
                case 'Following Up (Automated)':
                case 'Following Up (Manual)':
                    return 'Warm Lead';
                case 'Tony Finding Agent':
                case 'Mark Finding Agent':
                case 'Finding an Agent NOW':
                    return 'Finding an Agent';
                case 'Connecting to Agent':
                case 'Finding an Agent':
                case 'Matching to Agents':
                    return 'Connecting to Agent';
                case 'Already Has Agent':
                case 'Lost Opportunity':
                case 'Fell Through':
                case 'Test Points Issued':
                    return deal.milestone;
                case 'Closed':
                case 'Payment Instructions Sent':
                    return 'Closed';
            }
            break;

        case 'Transactions':
            switch (deal.milestone) {
                case 'Connected':
                case 'Introduced':
                case 'Shopping':
                case 'Listed':
                case 'Active':
                case 'Holding':
                    return 'Connected to Agent';
                case 'Closed':
                case 'Payment Received':
                case 'Test Points Issued':
                case 'Test Points Confirmed':
                case 'Waiting for AmEx Connection':
                    return 'Points Pending';
                case 'In Escrow':
                case 'Payment Instructions Sent':
                case 'Property Details Rcvd':
                case 'Property Details Received':
                case 'Payment Instructions Confirmed':
                    return 'In Escrow';
                case 'Review Complete':
                case 'Points Issued':
                    return 'Points Issued';
                case 'Fell Through':
                    return deal.milestone;
            }
            break;
    }

    return 'UNMAPPED';
}

// const SITE_BASE = 'https://joinrbn.com';

const HAWKE_PAGE = /rewards|earn-points/;
const TPG_PAGE = /thepointsguy/;

const SEARCH_REFERRER = /(?:google|bing)\.com/;
const TPG_REFERRER = /thepointsguy\.com/;
const AMEX_REFERRER = /americanexpress\.com/;

export function getPartner(campaign:any): string {
    const {referrer='', page='', partner='', utm_campaign=''} = (campaign||{});

    if (partner)
        return partner;

    if (HAWKE_PAGE.test(page))
        return 'Hawke';

    if (TPG_PAGE.test(page) || TPG_REFERRER.test(referrer))
        return 'The Points Guy';

    if (AMEX_REFERRER.test(referrer))
        return 'AmEx';

    if (SEARCH_REFERRER.test(referrer) && !utm_campaign)
        return 'Organic';

    return 'Direct';
}

// function formatCampaign(campaign:any) {
//     if (!campaign)
//         return undefined;
//
//     const {referrer, page, ...params} = campaign;
//
//     const keys = Object.keys(params);
//     keys.sort();
//     keys.unshift('page', 'referrer');
//
//     return keys.map(k => `${k}: ${campaign[k]}`).join('\n');
// }
